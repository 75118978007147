export var initialForm = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
     tabData: [ 
    {
        label: 'Profit Center',
        type: 'text',
        model: 'profitCenter',
        required: true,
        size: 4,
        maxLength:10,
        massKey: "Profit_Center",
        
      },
      {
        label: 'Controlling Area',
        type: 'select',
        model: 'controllingArea',
        method: 'api',
        source: 'controllingArea',
        reloadFields: ['group'],
        reloadParam: 'CoArea',
        screen: ['basic'],
        required: true,
        data: [],
        fieldValues: ['Kokrs'],
        takeValue: 'Kokrs',
        showValues: [ 'Kokrs'],
        size: 4,
        maxLength: 4,
        massKey: "Controlling_Area",
        
      },
    ],
    validations: [{
      fields: ['profitCenter'],
      keys: ['ProfitCenter'],
      source: 'checkDuplicacy',
      model: 'ProfitCenter',
      onSelect: ['profitCenter'],
      size: 4,
    }],

  };
  