export var basicForm2 = {
    value: 'basic2',
    label: 'Basic Data 2',
    type: 'standard',
    tabData: [
      // {
      //   label: 'Fashion Attribute 1',
      //   type: 'text',
      //   model: 'fashionAttribute1',
      //   size: 4
      // },
      // {
      //   label: 'Fashion Attribute 2',
      //   type: 'text',
      //   model: 'fashionAttribute2',
      //   size: 4
      // },
      // {
      //   label: 'Fashion Attribute 3',
      //   type: 'text',
      //   model: 'fashionAttribute3',
      //   size: 4
      // },
      {
        label: 'Season Year',
        type: 'number',
        model: 'seasonYear',
        size: 4,
      },
      {
        label: 'Season',
        model: 'Season',///ngModel
        type: 'text',
        size: 4
      },
      {
        label: 'Collection',
        type: 'text',
        model: 'collection',
        size: 4
      },
      {
        label: 'Theme',
        type: 'text',
        model: 'theme',
        size: 4
      },
    ]
  };
  