// export var initialForm = {
//     value: 'initial',
//     label: 'Initial Screen',
//     type: 'standard',
//     tabData: [{
//         label: 'Material',
//         type: 'text',
//         model: 'material',
//         required: true,
//         massKey: "MATERIAL"
//         // disabled: true
//       },
//       {
//         label: 'Plant',
//         type: 'select',
//         method: 'api',
//         source: 'plant',
//         model: 'plant',
//         data: [],
//         required: true,
//         fieldValues: ['Werks', 'Name1'],
//         takeValue: 'Werks',
//         showValues: ['Werks', 'Name1'],
//         size: 4,
//         massKey: "PLANT"
//       },
//       {
//         label: 'BOM Usage',
//         type: 'select',
//         method: 'api',
//         source: 'bomUsageSet',
//         model: 'bomUsage',
//         data: [],
//         required: true,
//         fieldValues: ['Stlan', 'Antxt'],
//         takeValue: 'Stlan',
//         showValues: ['Stlan', 'Antxt'],
//         size: 4,
//         massKey: "BOM_USAGE"
//       }
//       ],
//       validations: [{
//         fields: ['material', 'plant'],
//         keys: ['Matnr', 'Werks'],
//         source: 'materialInPlantValidation',
//         model: 'BOM',
//         onSelect: ['material', 'plant'],
//         size: 4
//       },
//       {
//         fields: ['material', 'plant', 'bomUsage'],
//         keys: ['Matnr', 'Werks', 'Stlan'],
//         source: 'altBomSet',
//         model: 'BOM',
//         onSelect: ['bomUsage'],
//         size: 4
//       }],
//   };


  export var initialForm = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
    tabData: [{
      label: 'Material',
      type: 'text',
      model: 'material',
      required: true,
      massKey: "MATERIAL",
      reloadFields: ['plant'],
      reloadParam: 'Matnr',
      // disabled: true
      size:4
    },
    {
      label: 'Plant',
      type: 'select',
      method: 'api',
      source: 'plant',
      model: 'plant',
      data: [],
      required: true,
      fieldValues: ['Werks'],
      takeValue: 'Werks',
      showValues: ['Werks'],
      size: 4,
      massKey: "PLANT",
      flagReload: true
    },
    {
      label: 'BOM Usage',
      type: 'select',
      method: 'api',
      source: 'bomUsageSet',
      model: 'bomUsage',
      data: [],
      required: true,
      fieldValues: ['Stlan', 'Antxt'],
      takeValue: 'Stlan',
      showValues: ['Stlan', 'Antxt'],
      size: 4,
      massKey: "BOM_USAGE"
    }
    ],
    validations: [{
      fields: ['material', 'plant'],
      keys: ['Matnr', 'Werks'],
      source: 'materialInPlantValidation',
      model: 'BOM',
      onSelect: ['material', 'plant'],
      size: 4
    },
    // validations: [{
    //   fields: ['material', 'plant'],
    //   keys: ['Matnr', 'Werks'],
    //   source: 'materialInPlantValidation',
    //   method: 'api',
    //   model: 'BOM',
    //   data: {},
    //   fieldValues: ['Werks', 'Matnr'],
    //   takeValue: 'Werks',
    //   showValues: ['Werks', 'Matnr'],
    //   onSelect: ['material', 'plant'],
    //   reloadFields: ['plant'],
    //   reloadParam: 'Matnr',
    //   size: 4
    // },
    {
      fields: ['material', 'plant', 'bomUsage'],
      keys: ['Matnr', 'Werks', 'Stlan'],
      source: 'altBomSet',
      model: 'BOM',
      onSelect: ['bomUsage'],
      size: 4
    }],
  };
  
  