import { Component, OnInit ,Input} from '@angular/core';
import { Route, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-view-purchase-price',
  templateUrl: './view-purchase-price.page.html',
  styleUrls: ['./view-purchase-price.page.scss'],
})
export class ViewPurchasePricePage implements OnInit {
  @Input() request: any;
  @Input() domain: any;
  org:any="001"
  constructor(public modalController:ModalController,
    private router: Router) { }

  ngOnInit() {
    console.log("request ", this.request)
    console.log("domain ", this.domain)
  }
  close(){
    this.modalController.dismiss();
  }
}
